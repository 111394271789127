<template>
  <div class="wrapper-field">
    <v-form ref="form" class="fields-profile">
      <div class="edit-information">
        <h1>Profile</h1>
        You can view and edit information about yourself
      </div>
      <div class="image-links">
        <div class="image-field">
          <div class="image-input">
            <div class="placeholder">
              <img
                v-if="!form.picture"
                class="image-download"
                src="../../assets/icon-download-image.svg"
                alt=""
              />
              <img
                ref="fileInput"
                class="avatar-user"
                :src="form.picture"
                aspect-ratio="1"
              />
              <input
                @change="onFileChange"
                accept="image/png, image/jpeg, image/bmp"
                class="input-file"
                name="myFile"
                type="file"
              />
            </div>
            <div class="image-target-wrapper">
              <v-dialog
                v-if="isSize"
                v-model="dialog"
                content-class="avatar-dialog"
              >
                <v-card>
                  <v-card-text style="padding-top: 0 !important">
                    <VueCropper
                      style="max-height: 80vh !important"
                      v-show="selectedFile"
                      ref="cropper"
                      :src="selectedFile"
                      alt="Source Image"
                      :autoCropArea="1"
                      :aspectRatio="1 / 1"
                      :initialAspectRatio="1 / 1"
                    ></VueCropper>
                  </v-card-text>
                  <v-card-actions
                    class="image-crop--action"
                    style="padding-left: 10px !important"
                  >
                    <v-btn
                      class="primary"
                      @click="saveImage(), (dialog = false)"
                      >Save</v-btn
                    >
                    <v-btn color="primary" text @click="dialog = false"
                      >Cancel</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogSize" class="dialog-warning" else>
                <v-card-text class="warning-size"
                  ><div>File size must be less than 5 mb</div>
                </v-card-text>
                <v-card-actions class="image-crop--action warning-size">
                  <v-btn class="primary" text @click="dialogSize = false"
                    >Close</v-btn
                  >
                </v-card-actions>
              </v-dialog>
            </div>
          </div>
        </div>
        <div class="profile-text-links">
          <a
            class="admin-panel"
            v-show="form.role === 'administrator'"
            href="/admin"
          >
            <img src="../../assets/icon-admin-profile.svg" alt="" />
            Admin panel
          </a>
          <a href="/password"
            ><img src="../../assets/icon-lock-profile.svg" alt="" />Change
            password</a
          >
        </div>
      </div>
      <v-row>
        <v-col cols="12">
          <profile-data
            v-bind:form="form"
            @printResponse="showNotification"
            state="myProfile"
          />
        </v-col>
        <loader-component v-if="isUpdating"></loader-component>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import awsAuth from "@/cognito/aws-auth";

import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import LoaderComponent from "@/components/OverlayComponent";

import ProfileData from "./ProfileData";
import { mapActions } from "vuex";

export default {
  components: {
    LoaderComponent,
    ProfileData,
    VueCropper,
  },
  data() {
    return {
      form: {},
      isUpdating: false,
      avatar: "",
      isAdmin: false,
      files: null,

      mime_type: "",
      cropedImage: "",
      autoCrop: false,
      selectedFile: "",
      image: "",
      dialog: false,
      dialogSize: false,
      isSize: true,
    };
  },

  mounted() {
    const admin = awsAuth.isUserAdmin();
    this.isAdmin = admin === "true" || admin === true;
    this.$nextTick(() => {
      this.getProfileData();
    });
  },

  methods: {
    ...mapActions("ui", ["snackbar"]),
    getProfileData() {
      this.isUpdating = true;
      awsAuth
        .userData()
        .then((response) => {
          awsAuth
            .getUserGroups()
            .then((resp) => {
              if (resp != null) {
                let allRoles = ["administrator", "guest", "presenter"];
                let role = resp
                  .filter((el) => {
                    return allRoles.includes(el);
                  })
                  .join(",");
                this.form = {
                  username: response.data.username,
                  fullname: response.data.name ? response.data.name : "",
                  email: response.data.email,
                  picture: response.data.picture,
                  gender: response.data.gender ? response.data.gender : "",
                  group: response.data.family_name
                    ? response.data.family_name.toLowerCase()
                    : "nocompany",
                  role: role,
                };
              }
            })
            .catch((error) => {
              this.snackbar({
                color: "error",
                icon: "mdi-cancel",
                title: "Error",
                text: error.message,
              });
            });
        })
        .finally(() => {
          this.isUpdating = false;
        });
    },
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (files[0].size > 5242880) {
        this.isSize = false;
        this.dialogSize = true;
      } else {
        this.isSize = true;
        if (!files.length) return;
        this.handleUpdate(files[0]);
      }
    },
    handleUpdate(file) {
      this.dialog = true;
      const fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        this.selectedFile = e.target.result;
        this.$refs.cropper.replace(this.selectedFile);
      };

      fileReader.readAsDataURL(file);
    },
    saveImage() {
      this.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.isUpdating = true;
      //make request to upload image to s3
      const payload = {
        fileContent: this.cropedImage,
        username: this.form.username,
      };
      awsAuth
        .uploadImage(payload)
        .then((res) => {
          this.form.picture = res.data.data.Location;
          awsAuth
            .updateAttributes({
              picture: this.form.picture,
            })
            .then((result) => {
              window.location.reload();
              this.snackbar({
                color: "success",
                icon: "mdi-check-circle",
                title: "Success",
                text: `${result.data}, avatar updated!`,
              });

              this.$refs.fileInput.src = this.cropedImage;
              this.isUpdating = false;
            })
            .catch((error) => {
              this.snackbar({
                color: "error",
                icon: "mdi-cancel",
                title: "Error",
                text: error.message,
              });
              this.isUpdating = false;
            });
        })
        .catch((error) => {
          this.snackbar({
            color: "error",
            icon: "mdi-cancel",
            title: "Error",
            text: error.message,
          });
        })
        .finally(() => {
          this.isUpdating = false;
        });
    },
    saveProfileData() {
      //placeholder. Not used.
      this.isUpdating = true;
      awsAuth
        .updateAttributes({
          name: this.form.name,
          gender: this.form.gender,
          picture: this.form.picture,
        })
        .then((result) => {
          this.isUpdating = false;
          this.snackbar({
            color: "success",
            icon: "mdi-check-circle",
            title: "Success",
            text: `${result.data}, profile updated!`,
          });
        })
        .catch((error) => {
          this.isUpdating = false;
          this.snackbar({
            color: "error",
            icon: "mdi-cancel",
            title: "Error",
            text: error.message,
          });
        });
    },
    updateAttr() {
      let data = {
        name: this.form.name,
        gender: this.form.gender,
        picture: this.form.picture,
      };
      awsAuth
        .updateAttributes(data)
        .then((result) => {
          this.isUpdating = false;
          this.snackbar({
            color: "success",
            icon: "mdi-check-circle",
            title: "Success",
            text: `${result.data}, profile updated!`,
          });
        })
        .catch((error) => {
          this.isUpdating = false;
          this.snackbar({
            color: "error",
            icon: "mdi-cancel",
            title: "Error",
            text: error.message,
          });
        });
    },

    showNotification(msg, success) {
      this.snackbar({
        color: success ? "success" : "error",
        icon: success ? "mdi-check-circle" : "mdi-cancel",
        title: success ? "Success" : "Error",
        text: msg,
      });
      this.isUpdating = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 581px) {
  .avatar-user {
    width: 170px;
    border-radius: 50%;
  }

  .wrapper-field {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(233, 247, 249, 0.5);
    width: 100vw;
    height: calc(100vh - 12vh);
  }
  .fields-profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: white;
    width: 50vw;
    // height: 80vh;
    padding: 2vw 1vw 1vw 6vw;
    border: 1px solid #e9eeef;
    border-radius: 4px;
  }
  .image-field {
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
    align-items: center;
  }
  .edit-information {
    font-style: normal;
    font-weight: normal;
    font-size: 2vh;
    line-height: 100%;
    color: #99b4b9;
    margin-bottom: 4vh;
    h1 {
      font-weight: 600;

      line-height: 200%;
      color: #252733;
    }
  }
  .image-input {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    position: relative;

    .image-target-wrapper {
      position: absolute;
      bottom: 10px;
      right: 25px;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-end;
      align-items: flex-end;

      .image-target > * {
        margin: 0 !important;
      }
      .image-target {
        position: absolute;
        top: 50%;
        // transform: translateY(-50%);
        margin: 0 !important;
      }

      .v-text-field {
        margin: 0 !important;
        padding: 0 !important;
      }
    }

    .placeholder {
      border-radius: 50%;
      width: 150px;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .image-crop--action {
      padding-left: 10px !important;
    }
  }
  .avatar-dialog {
    max-width: 500px;
    width: auto !important;
  }
  .image-download {
    width: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .input-file {
    position: absolute;
    width: 170px;
    height: 170px;
    opacity: 0;
    border-radius: 50%;
  }
  .profile-text-links {
    display: flex;
    flex-direction: column;
    margin-left: 2vw;
    width: 100vw;
    line-height: 300%;
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      img {
        margin-right: 10px;
      }
    }
  }

  .image-links {
    display: flex;
    align-items: center;
    margin-bottom: 4vh;
    div {
      font-weight: 500;
      font-size: 14px;
      color: #1eafc1;
    }
  }
  .warning-size {
    font-size: 18px;
    color: red;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    text-align: center;
    padding-left: 50px !important;
  }
  .dialog-warning {
    width: 50% !important;
  }

}
@media screen and (max-width: 580px) and (orientation: portrait){
  .avatar-user {
    width: 120px;
    border-radius: 50%;
  }

  .fields-profile {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 90vw;
    border-radius: 4px;
    margin-left: 20px;
    padding-top: 6vw;
  }
  .edit-information {
    font-style: normal;
    font-weight: normal;
    text-align: center;
    font-size: 2vh;
    line-height: 140%;
    color: #99b4b9;
    margin-bottom: 1vh;
    h1 {
      font-weight: 600;
      line-height: 200%;
      color: #252733;
    }
  }
  .image-input {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;

    .image-target-wrapper {
      position: absolute;
      bottom: 5px;
      right: 25px;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-end;
      align-items: flex-end;

      .image-target > * {
        margin: 0 !important;
      }
      .image-target {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 !important;
      }

      .v-text-field {
        margin: 0 !important;
        padding: 0 !important;
      }
    }

    .placeholder {
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .image-crop--action {
      padding-left: 10px !important;
    }
  }
  .avatar-dialog {
    // max-width: 500px;
    width: auto !important;
  }
  .image-download {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .input-file {
    position: absolute;
    width: 120px;
    height: 120px;
    opacity: 0;
    border-radius: 50%;
  }
  .profile-text-links {
    display: flex;
    flex-direction: column;
    margin-left: 4vw;
    width: 100vw;
    line-height: 300%;
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      img {
        margin-right: 10px;
      }
    }
    .admin-panel {
      display: none !important;
    }
  }

  .image-links {
    display: flex;
    align-items: center;
    margin-bottom: 2vh;
    margin-left: 10px;
    div {
      font-weight: 500;
      font-size: 14px;
      color: #1eafc1;
    }
  }
  .warning-size {
    font-size: 14px;
    color: red;
    background-color: rgba(233, 247, 249, 0.5);
    padding-left: 10px !important;
  }
}

@media screen and (min-width: 581px) and (max-width: 1024px) and (orientation: portrait){
  .avatar-user {
    width: 320px;
    border-radius: 50%;
  }
  .wrapper-field {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(233, 247, 249, 0.5);
    width: 100vw;
    height: calc(100vh - 12vh);
  }
  .fields-profile {
    width: 80vw;
    padding: 2vw 1vw 1vw 6vw;
    border: 1px solid #e9eeef;
    border-radius: 4px;
  }
  .edit-information {
    font-size: 2vh;
    margin-bottom: 4vh;
    h1 {
      font-weight: 600;
      font-size: 3vh;
    }
  }
  .image-input {
    .image-target-wrapper {
      width: 50px;
      height: 50px;
    }

    // .placeholder {
    //   width: 170px;
    //   height: 170px;

    // }
  }
  .avatar-dialog {
    // max-width: 500px;
  }
  .image-download {
    width: 320px;
  }
  .input-file {
    width: 320px;
    height: 320px;
    opacity: 0;
  }
  .profile-text-links {
    margin-left: 4vw;
    width: 40vw;
    a {
      font-size: 20px;
      img {
        margin-right: 20px;
        width: 30px;
      }
    }
  }

  .image-links {
    margin-bottom: 4vh;
    div {
      font-weight: 500;
      font-size: 14px;
      color: #1eafc1;
    }
  }
  .warning-size {
    font-size: 18px;
    color: red;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    text-align: center;
    padding-left: 50px !important;
  }
  .dialog-warning {
    width: 50% !important;
  }
}
@media screen and (min-width: 3800px) {
  .avatar-user {
    width: 320px;
    border-radius: 50%;
  }

  .edit-information {
    font-size: 2vh;
    line-height: 150%;
    color: #99b4b9;
    margin-bottom: 4vh;
    h1 {
      font-weight: 600;

      line-height: 200%;
      color: #252733;
    }
  }
  .image-input {
    .image-target-wrapper {
      position: absolute;
      bottom: 10px;
      right: 25px;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-end;
      align-items: flex-end;

      .image-target > * {
        margin: 0 !important;
      }
      .image-target {
        top: 50%;
        // transform: translateY(-50%);
        margin: 0 !important;
      }

      .v-text-field {
        margin: 0 !important;
        padding: 0 !important;
      }
    }

    .placeholder {
      border-radius: 50%;
      width: 320px;
      height: 320px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .image-crop--action {
      padding-left: 10px !important;
    }
  }
  .avatar-dialog {
    max-width: 500px;
    width: auto !important;
  }
  .image-download {
    width: 320px;
  }
  .input-file {
    width: 320px;
    height: 320px;
  }
  .profile-text-links {
    a {
      img {
        margin-right: 20px;
        width: 50px;
      }
    }
  }

  .image-links {
    margin-bottom: 4vh;
    div {
      font-size: 36px;
    }
  }
  .warning-size {
    font-size: 32px;

    color: red;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    text-align: center;
    padding-left: 50px !important;
  }
  .primary {
    height: 60px !important;
    width: 150px !important;
  }
}
@media screen and (max-device-width: 1366px) and (orientation: landscape) {
  .v-dialog__content {
    align-items: flex-start;
    justify-content: flex-start;
    margin: 5% 0% 0% 10%;
    @media screen and (max-device-width: 1024px) {
      margin: 0% 0% 0% 25%;
      align-items: center;
    }
  }
  .dialog-warning {
    width: 50% !important;
    margin: 10% 0% 0% 25%;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
</style>
<style>
.v-dialog {
  width: 50% !important;
  z-index: inherit;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
}
</style>
