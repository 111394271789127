<template>
  <v-card flat class="profile-data">
    <v-card flat v-if="head" class="mb-8">
      <h2>{{ head }}</h2>
    </v-card>
    <v-card flat>
      <div class="row-fields">
        <v-text-field
          label="Full name"
          v-model="form.username"
          outlined
          class="data-text-field"
          :rules="loginRules"
          disabled
        >
          <template v-slot:prepend-inner>
            <img
              class="profile-icon"
              height="20px"
              src="../../assets/icon-people-profile.svg"
              alt=""
            />
          </template>
        </v-text-field>
        <v-text-field v-model="form.email" label="Email" outlined disabled>
          <template v-slot:prepend-inner>
            <img
              class="profile-icon"
              height="20px"
              src="../../assets/icon-email-profile.svg"
              alt=""
            />
          </template>
        </v-text-field>
      </div>

      <div class="row-fields company-field">
        <v-text-field
          label="Company"
          class="data-text-field"
          v-model="form.group"
          outlined
          disabled
        >
          <template v-slot:prepend-inner>
            <img
              class="profile-icon"
              height="20px"
              src="../../assets/icon-company-profile.svg"
              alt=""
            /> </template
        ></v-text-field>
      </div>
    </v-card>
  </v-card>
</template>

<script>
import awsAuth from "@/cognito/aws-auth";

export default {
  props: {
    state: {
      type: String,
      required: true,
    },
    head: {
      type: String,
      required: false,
    },
    form: {
      type: Object,
      required: true,
    },
  },
  name: "ProfileData",
  data() {
    return {
      myForm: {},
      isAdmin: false,
      showGender: false,
      groups: [],
      allGroups: [],
      allRoles: ["administrator", "guest", "presenter"],
      role: "",
      company: "",
      loginRules: [
        // value => !!value || 'Required.',
        // value => /^+$/.test(value) || 'Must be a valid data',
      ],
    };
  },
  computed: {
    roleEnable() {
      return this.isAdmin === true;
    },
    usernameEnable() {
      return this.isAdmin === true && this.state === "addUser";
    },
  },

  mounted() {
    let self = this;

    const admin = awsAuth.isUserAdmin();
    this.isAdmin = admin === "true" || admin === true;
    this.role = this.form.role;
    this.company = this.form.family_name;

    awsAuth
      .getAllGroups()
      .then((resp) => {
        self.allGroups = resp.data.map((x) => x.Username);
      })
      .catch((err) => {
        console.log(err);
      });
    if (this.state === "myProfile") {
      // awsAuth
      //     .getUserGroups()
      //     .then((response)=> {
      //         if (response != null) {
      //             self.role = response.filter((el) => { return self.allRoles.includes(el) }).join(",");
      //             self.groups = response.filter((el) => { return !self.role.includes(el) });
      //             // self.groups = response.filter((el) => { return !self.role.includes(el) });
      //             // console.log("self.groups = " + self.groups)
      //         }
      //     })
      //     .catch(error => {
      //         console.log("error = " + error);
      //     })
    }
  },
  methods: {
    removeUserFromGroup(groupName) {
      let self = this;

      awsAuth
        .removeUserFromGroup(self.form.username, groupName)
        .then(() => {
          console.log(self.form.username + " removed from " + groupName);
        })
        .catch((error) => {
          console.log("removeUserFromGroup error - " + error.message);
          self.$emit("printResponse", error.message, false);
          self.isUpdating = false;
        });
    },
    addUserToGroup(groupName) {
      let self = this;
      awsAuth
        .addUserToGroup(self.form.username, groupName)
        .then(() => {
          console.log(self.form.username + " added to " + groupName);
        })
        .catch((error) => {
          console.log("addUserToGroup error - " + error.message);
          self.$emit("printResponse", error.message, false);
          self.isUpdating = false;
        });
    },
    updateUsersAttributes() {
      let self = this;
      awsAuth
        .adminUpdateAttributes(self.form.username, {
          name: self.form.fullname ? self.form.fullname : "",
          picture: self.form.picture ? self.form.picture : "",
          family_name: self.form.group ? self.form.group : "",
        })
        .then(() => {
          self.$emit("close");
          self.$emit("printResponse", "Profile updated!", true);
          self.isUpdating = false;
        })
        .catch((error) => {
          console.log(error);
          self.$emit("printResponse", error.message, false);
          self.isUpdating = false;
        });
    },

    checkArrays(arr1, arr2) {
      return arr1.filter((item) => arr2.indexOf(item) < 0);
    },

    saveProfileData() {
      let self = this;
      self.isUpdating = true;

      if (this.role && this.role != self.form.role) {
        self.removeUserFromGroup(self.role);
        self.addUserToGroup(self.form.role);
      }

      self.updateUsersAttributes();

      // awsAuth
      //   .getUsersGroups(self.form.username)
      //   .then((res) => {
      //     let userGroups = res.data.map((group) => {
      //       return group.GroupName;
      //     });
      //     let oldRole = userGroups
      //       .filter((el) => {
      //         return roles.includes(el);
      //       })
      //       .join("");
      //     let oldGroups = userGroups.filter((el) => {
      //       return !oldRole.includes(el);
      //     });

      //     var toDelete = self.checkArrays(oldGroups, self.form.groups);
      //     var toAdd = self.checkArrays(self.form.groups, oldGroups);

      //     if (oldRole != self.form.role) {
      //       if (oldRole != "") {
      //         self.removeUserFromGroup(oldRole);
      //       }
      //       self.addUserToGroup(self.form.role);
      //     }

      //     toDelete.forEach((group) => {
      //       if (group != "") {
      //         self.removeUserFromGroup(group);
      //       }
      //     });
      //     toAdd.forEach((group) => {
      //       if (group != "") {
      //         self.addUserToGroup(group);
      //       }
      //     });
      //     self.updateUsersAttributes();
      //   })
      //   .catch((error) => {
      //     console.log(`ERROR, ${error.message}!`);
      //   });
    },
  },
};
</script>

<style lang="scss" scoped>
.row-fields {
  display: flex;
  width: 40vw;
}
.profile-data {
  width: 40vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2vh;
}
.data-text-field {
  margin-right: 2vh;
}
.company-field {
  width: 20.5vw;
}
.company-field {
  width: 20.5vw;
}
.data-profile-btn {
  width: 50%;
  height: 8vh !important;
  margin-right: 25%;
  margin-left: 25%;
}
.profile-icon {
  margin-right: 0.5vw;
}
@media screen and (max-device-width: 1366px) and (orientation: landscape) {

 .data-text-field {
    margin-right: 2vh;
  }
  .company-field {
    width: 35.5vw;
  }
  .data-profile-btn {
    width: 50%;
    height: 8vh !important;
    margin-right: 25%;
    margin-left: 25%;
  }
  .profile-icon {
    margin-right: 1vw;
    height: 25px !important;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 580px) and (orientation: portrait) {
  .row-fields {
    flex-direction: column;
    width: 90vw;
  }
  .profile-data {
    width: 90vw;
    margin-top: 0vh;
  }
  .data-text-field {
    margin-right: 0vh;
  }
  .data-profile-btn {
    width: 100%;
    height: 8vh !important;
    margin-right: 0%;
    margin-left: 0%;
  }
  .profile-icon {
    margin-right: 1vw;
  }

  .data-text-field {
    margin-right: 0vh;
  }
}
@media screen and (min-width: 581px) and (max-width: 1024px) and (orientation: portrait) {
  .row-fields {
    width: 70vw;
  }
  .profile-data {
    width: 70vw;
    margin-top: 2vh;
  }
  .data-text-field {
    margin-right: 2vh;
  }
  .company-field {
    width: 35.5vw;
  }
  .data-profile-btn {
    width: 50%;
    height: 8vh !important;
    margin-right: 25%;
    margin-left: 25%;
  }
  .profile-icon {
    margin-right: 0.5vw;
    height: 25px !important;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 3800px) {
  .row-fields {
    width: 40vw;
  }
  .profile-data {
    width: 40vw;
    margin-top: 2vh;
  }
  .data-text-field {
    margin-right: 2vh;
  }
  .company-field {
    width: 20.5vw;
  }
  .data-profile-btn {
    width: 50%;
    height: 8vh !important;
    margin-right: 25%;
    margin-left: 25%;
  }
  .profile-icon {
    margin-right: 0.5vw;
    height: 50px !important;
    margin-bottom: 20px;
  }
}
</style>

<style>
.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgb(0 0 0 / 70%) !important;
}
@media screen and (min-width: 3800px) {
  .theme--light.v-input--is-disabled input,
  .theme--light.v-input--is-disabled textarea {
    font-size: 28px;
  }
}
</style>
